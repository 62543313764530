/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Button, Text, Image, ColumnCover, ColumnDiv, PriceWrap, GCalendar, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-ezot8q bg--top --full" name={"uvod"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pt--20 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--style8 subtitle-box--center fs--30 w--300 ls--50 mt--25" content={"<span style='color: white'>KRÁSA & WELLNESS</span>"}>
              </Subtitle>

              <Title className="title-box fs--185 w--300" style={{"marginTop":35}} content={"<span style='color: white'>Jméno Příjmení</span>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--style6 subtitle-box--center ff--2 fs--30 w--300 ls--50 lh--1 mt--10" content={""}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn5 ls--08 mt--50" content={"Rezervace"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--60" name={"informace"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="mt--10">
              
              <Title className="title-box fs--48 w--300 ls--001 lh--12" style={{"maxWidth":1000}} content={"Pečujte o své tělo i mysl.\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":950}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"navigace"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 pl--0 pr--0 flex--center" style={{"maxWidth":""}} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper className="--hvr4 mb--0 mt--0 pb--0 pt--0">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/87/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/87/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/87/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/87/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/87/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/87/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr2 btn-box--shadownull fs--26" content={"Relaxační masáž"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr4 mb--0 mt--0 pb--0 pt--0">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/87/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/87/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/87/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/87/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/87/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/87/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr2 btn-box--shadownull fs--26" content={"Úleva od bolesti"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-sbputh mt--80 pb--80 pt--60" name={"informace-2"} style={{"backgroundColor":"var(--color-supplementary)"}} css={css`
        
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/87/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--right mt--10 pr--20">
              
              <Title className="title-box fs--48 w--300" content={"<span style='color: white'>Pečujte o své tělo i mysl.\n</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shadownull" content={"Přejít na nabídku masáží"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10 pr--20">
              
              <Subtitle className="subtitle-box w--300" content={"<span style='color: white'>— Dopřejte svému tělu kvalitní starostlivost</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300" content={"<span style='color: white'>— Ulevte si od chronických bolestí zad</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300" content={"<span style='color: white'>— Užijte si nepopsatelný relax po náročném dnu</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300" content={"<span style='color: white'>— Ulevte si od chronických bolestí zad</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--80" name={"cenik"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20" columns={"1"}>
            
            <ColumnWrapper className="mt--10">
              
              <Title className="title-box fs--102 w--300 lh--12" content={"Masáže"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":640}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--50 pb--30 pt--30" name={"cenik-2"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Masáže"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Relaxační masáž"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Úleva od bolesti"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Relaxační masáž (60 min.)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Párová masáž"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Regenerační masáž"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Masáže"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Relaxační masáž"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Úleva od bolesti"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Relaxační masáž (60 min.)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Párová masáž"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Regenerační masáž"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Masáže"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Relaxační masáž"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--2 fs--20 pb--0" content={"Úleva od bolesti"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--40 pb--0 pt--0" name={"fotogalerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/87/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/87/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/87/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/87/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/87/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/87/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/87/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/87/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/87/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/87/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/87/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/87/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/87/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/87/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/87/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/87/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/87/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/87/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/87/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/87/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/87/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/87/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/87/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/87/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--0 pb--80 pt--80" name={"informace-3"}>
          
          <ColumnWrap className="column__flex --center el--2 mb--20 flex--stretch" columns={"2"}>
            
            <ColumnWrapper className="--hvr4 mt--10 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Title className="title-box" content={"Relaxační masáž"}>
              </Title>

              <Text className="text-box mb--30" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Dopřejte svému tělu kvalitní starostlivost"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Ulevte si od chronických bolestí zad"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Užijte si nepopsatelný relax po náročném dnu"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Ulevte si od chronických bolestí zad"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shadownull mt--25" content={"Zamluvit termín"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr4 mt--10 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Title className="title-box" content={"Úleva od bolesti"}>
              </Title>

              <Text className="text-box mb--30" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Dopřejte svému tělu kvalitní starostlivost"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Ulevte si od chronických bolestí zad"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Užijte si nepopsatelný relax po náročném dnu"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Ulevte si od chronických bolestí zad"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shadownull mt--25" content={"Zamluvit termín"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr4 mt--10 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Title className="title-box" content={"Regenerační masáž"}>
              </Title>

              <Text className="text-box mb--30" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Dopřejte svému tělu kvalitní starostlivost"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Ulevte si od chronických bolestí zad"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Užijte si nepopsatelný relax po náročném dnu"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Ulevte si od chronických bolestí zad"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shadownull mt--25" content={"Zamluvit termín"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr4 mt--10 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Title className="title-box" content={"Párová masáž"}>
              </Title>

              <Text className="text-box mb--30" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Dopřejte svému tělu kvalitní starostlivost"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Ulevte si od chronických bolestí zad"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Užijte si nepopsatelný relax po náročném dnu"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Ulevte si od chronických bolestí zad"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shadownull mt--25" content={"Přejít na nabídku masáží"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1v5t3vz pb--60 pt--60" name={"rezervace"} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/7580/45a2a70ffe5f4dfa87ab300d102fba5e_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7580/45a2a70ffe5f4dfa87ab300d102fba5e_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7580/45a2a70ffe5f4dfa87ab300d102fba5e_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7580/45a2a70ffe5f4dfa87ab300d102fba5e_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7580/45a2a70ffe5f4dfa87ab300d102fba5e_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7580/45a2a70ffe5f4dfa87ab300d102fba5e_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7580/45a2a70ffe5f4dfa87ab300d102fba5e_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7580/45a2a70ffe5f4dfa87ab300d102fba5e_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":930}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style='color: var(--color-dominant)'>Pečujte o své tělo i mysl.\n</span>"}>
              </Title>

              <Text className="text-box mb--40" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

              <GCalendar src={"https://calendar.google.com/calendar/embed?height=800&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FPrague&src=dGg2Z2N1OHE3NWVzM3A5N21udDhxbWlucmdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%234285F4&showNav=1&showDate=1&showPrint=0&showTabs=1&showCalendars=0&showTz=0&mode=MONTH"}>
              </GCalendar>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--0 pb--80 pt--60" name={"rezervace-2"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--bottom" columns={"2"}>
            
            <ColumnWrapper className="--right mt--10 pr--20">
              
              <Title className="title-box fs--48 w--300 ls--001 lh--12" content={"<span style='color: var(--color-dominant)'>Pečujte o své tělo i mysl.\n</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shadownull" content={"Přejít na nabídku masáží"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10 pr--20">
              
              <Subtitle className="subtitle-box w--300" content={"<span style='color: var(--color-dominant)'>— Dopřejte svému tělu kvalitní starostlivost</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300" content={"<span style='color: var(--color-dominant)'>— Ulevte si od chronických bolestí zad</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300" content={"<span style='color: var(--color-dominant)'>— Užijte si nepopsatelný relax po náročném dnu</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300" content={"<span style='color: var(--color-dominant)'>— Ulevte si od chronických bolestí zad</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--0" name={"rezervace-3"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 --right ff--1 fs--16" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]} layout={"l3"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--60" name={"paticka"}>
          
          <ColumnWrap className="column__flex el--4" columns={"4"}>
            
            <ColumnWrapper className="mt--10">
              
              <Title className="title-box fs--48 w--300 lh--12" content={"Wellness"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10">
              
              <Title className="title-box fs--48 w--300 lh--12" content={"Adresa"}>
              </Title>

              <Text className="text-box" content={"Heršpacká 18/342<br> Brno 635 00<br>Česká republika"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10">
              
              <Title className="title-box fs--48 w--300 lh--12" content={"Kontakt"}>
              </Title>

              <Text className="text-box" content={"+420 797 811 2X0<br>info@vase-stranky.com<br><a href=\"https://saywebpage.com\">Tvorba web stránek</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right mt--10">
              
              <Text className="text-box ff--3 fs--24 mt--0" content={"<a href=\"#cenik\">Masáže</a>"}>
              </Text>

              <Text className="text-box ff--3 fs--24 mt--0" content={"<a href=\"#informace\">O nás</a>"}>
              </Text>

              <Text className="text-box ff--3 fs--24 mt--0" content={"<a href=\"#[[UNIsectionname12-2]]\">Služby</a><br>"}>
              </Text>

              <Text className="text-box ff--3 fs--24 mt--0" content={"<a href=\"#paticka\">Kontakt</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}